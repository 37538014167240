import styles from "./LoadingSpinner.module.scss"

export default function LoadingSpinner() {
  return (
    <div className={styles.center}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </div>
      </div>
    </div>
  )
}
