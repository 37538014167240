import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { flashReducer } from "./flashSlice"

export default configureStore({
  reducer: {
    flash: flashReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
