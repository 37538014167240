import { doc, onSnapshot } from "firebase/firestore"
import { lazy, useEffect, useState } from "react"
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom"
import { db } from "../firebase"
import Delivery, { deliveryConverter } from "../models/Delivery"

const PaymentFailure = lazy(
  () => import("../payments/payment-complete/PaymentFailure")
)
const PaymentSuccess = lazy(
  () => import("../payments/payment-complete/PaymentSuccess")
)
const PaymentSummaryPage = lazy(
  () => import("../payments/payment-summary/PaymentSummaryPage")
)
const DeliveryDetailPage = lazy(() => import("./DeliveryDetailPage"))

export default function DeliveryPage() {
  const location = useLocation()
  const { deliveryId } = useParams()
  const [delivery, setDelivery] = useState<Delivery>()
  const navigate = useNavigate()

  useEffect(() => {
    if (deliveryId) {
      const unsub = onSnapshot(
        doc(db, "deliveries", deliveryId).withConverter(deliveryConverter),
        (deliveryDoc) => {
          if (!deliveryDoc.exists()) {
            navigate("/404")
            return
          }
          setDelivery(deliveryDoc.data())
        }
      )

      return () => {
        unsub()
      }
    }
    return () => true
  }, [deliveryId, navigate])

  return (
    <Routes>
      <Route path="/" element={<DeliveryDetailPage delivery={delivery} />}>
        <Route
          path="/payment/success"
          element={<PaymentSuccess delivery={delivery} />}
        />
        <Route
          path="/payment/failure"
          element={<PaymentFailure delivery={delivery} />}
        />
      </Route>

      <Route
        path="/preview"
        element={<DeliveryDetailPage delivery={delivery} preview />}
      />

      <Route
        path="/payment"
        element={<PaymentSummaryPage delivery={delivery} />}
      />
    </Routes>
  )
}
