import { createSlice } from "@reduxjs/toolkit"

/* eslint-disable no-param-reassign */

const flashSlice = createSlice({
  name: "flash",
  initialState: {
    message: null,
    type: null,
    icon: null,
    action: null,
    shouldDisappear: undefined,
  },
  reducers: {
    setFlash(state, action) {
      state.message = action.payload.message
      state.type = action.payload.type
      state.icon = action.payload.icon
      state.action = action.payload.action
      state.shouldDisappear = action.payload.shouldDisappear
    },
    removeFlash(state) {
      state.message = null
      state.type = null
      state.icon = null
      state.action = null
      state.shouldDisappear = undefined
    },
  },
  extraReducers: {},
})

const flashReducer = flashSlice.reducer
export { flashReducer }
export const { setFlash, removeFlash } = flashSlice.actions
