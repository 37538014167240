import { initReactI18next } from "react-i18next"
import en from "./translations/en.json"
import is from "./translations/is.json"
// import detector from "i18next-browser-languagedetector"
import i18n from "i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"

i18n
  // .use(detector)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      is: {
        translation: is,
      },
    },
    fallbackLng: "en",
    detection: {
      order: ["path", "localStorage"],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
